
pre {
  padding: 0.5rem 1rem;
  background-color: #d8d8d8;
}
.simplesearch {
  position: relative;
  width: 300px;
}
.simplesearch.opened .autocomplete-input-group .autocomplete-input {
  border-color: #7085a5;
}
.simplesearch.opened .autocomplete-input-group .input-icon {
  background-color: #d8d8d8;
  color: #7085a5;
}
.simplesearch .autocomplete,
.simplesearch .autocomplete-input-group {
  width: 100%;
}
.simplesearch .autocomplete .input-icon,
.simplesearch .autocomplete-input-group .input-icon {
  position: absolute;
  left: 100%;
  top: 3px;
  margin-left: -37px;
  height: calc(40px - 6px);
  width: calc(40px - 6px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #919191;
}
.simplesearch .autocomplete .input-icon .icon,
.simplesearch .autocomplete-input-group .input-icon .icon {
  font-weight: bold;
}
.simplesearch .autocomplete .input-icon:hover,
.simplesearch .autocomplete-input-group .input-icon:hover {
  background-color: #d8d8d8;
  color: #4e608a;
}
.simplesearch .autocomplete .autocomplete-input,
.simplesearch .autocomplete-input-group .autocomplete-input {
  outline: none;
  height: 40px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  width: 100%;
  font-size: 0.75rem;
  padding: 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: calc(40px / 2);
}
.simplesearch .autocomplete-list {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  position: absolute;
  bottom: -5px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  text-align: left;
  width: 100%;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
          box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  overflow-y: auto;
  color: #494949;
}
.simplesearch .autocomplete-list .autocomplete-list-group-title {
  background-color: #f0f0f0;
  font-weight: 600;
}
.simplesearch .autocomplete-list .autocomplete-list-item {
  font-weight: 600;
}
.simplesearch .autocomplete-list .autocomplete-list-item:hover,
.simplesearch .autocomplete-list .autocomplete-list-item.autocomplete-item-active {
  background-color: #eceff3;
}
.simplesearch .autocomplete-list .autocomplete-list-item,
.simplesearch .autocomplete-list .autocomplete-list-group-title {
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 12px;
  font-size: 0.75rem;
}
.simplesearch .autocomplete-list .autocomplete-list-item .autocomplete-item,
.simplesearch .autocomplete-list .autocomplete-list-group-title .autocomplete-item {
  white-space: pre;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.simplesearch .autocomplete-list .autocomplete-list-item .autocomplete-id,
.simplesearch .autocomplete-list .autocomplete-list-group-title .autocomplete-id {
  font-size: 9px;
  font-weight: 400;
  color: #a8a8a8;
}
.simplesearch .autocomplete-list .autocomplete-list-item .autocomplete-id svg,
.simplesearch .autocomplete-list .autocomplete-list-group-title .autocomplete-id svg {
  width: 9px;
  margin-left: 4px;
}
.autocomplete-list .autocomplete-list-item.no-matches:hover {
    background-color: unset;
    cursor: auto;
}
